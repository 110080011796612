<script setup lang="ts">
import { storeToRefs } from 'pinia'

import { Base400Black, Sm400Black, Sm400Gray } from '~/components/typography'
import VCard from '~/components/ui/VCard.vue'
import VText from '~/components/ui/VText.vue'

import { useMiscResizeStore } from '~/stores/miscResize'
import { useTagStore } from '~/stores/tag'

import pictureBuild from '~/helpers/images/picture_build'

import { Breakpoints } from '~/enums/breakpoints'

import type { IndexPartialInfluencer } from '~/pages/index/references'
import type { Influencer, StatsV3Influencer } from '~/types/influencer'

type KindComponent = typeof Sm400Black | typeof Base400Black

type Props = {
  influencer: StatsV3Influencer | Influencer | IndexPartialInfluencer
}
const props = defineProps<Props>()

const { t } = useI18n()

const { SCREEN_WIDTH } = storeToRefs(useMiscResizeStore())
const { GET_TAGS_FROM_IDS } = useTagStore()

function objIsPartialInfluencer(obj: any): obj is IndexPartialInfluencer {
  return (
    typeof obj.id === 'number' &&
    typeof obj.title === 'string' &&
    typeof obj.subtitle === 'string' &&
    typeof obj.picture === 'string'
  )
}

const titleConfig = computed(() =>
  SCREEN_WIDTH.value >= Breakpoints.sm ? 'sans/16/medium' : 'sans/20/bold',
)

const kindComponent = computed<KindComponent>(() =>
  SCREEN_WIDTH.value < Breakpoints.sm
    ? markRaw(Sm400Black)
    : markRaw(Base400Black),
)

const countries = computed<string>(() => {
  if (objIsPartialInfluencer(props.influencer)) return ''

  return GET_TAGS_FROM_IDS(props.influencer.tags?.identity.country ?? [])
    .map((tag) => t(`tags.country.${tag.name}`))
    .join(', ')
})

const kinds = computed<string>(() => {
  if (objIsPartialInfluencer(props.influencer)) return props.influencer.subtitle

  return GET_TAGS_FROM_IDS(
    props.influencer.tags?.identity.influencer_kind ?? [],
  )
    .map((tag) => t(`tags.influencer_kind.${tag.name}`))
    .join(', ')
})

const entity = computed<string>(() => {
  if (objIsPartialInfluencer(props.influencer)) return props.influencer.title
  return props.influencer.entity
})

const picture = computed<string>(() => {
  if (objIsPartialInfluencer(props.influencer)) return props.influencer.picture
  return pictureBuild({
    slug: props.influencer.has_profile_picture
      ? props.influencer.slug
      : undefined,
    size: '200_200',
    target: 'profile_picture',
    kind: 'influencer',
  })
})
</script>

<template>
  <VCard padding="0" class="tw-h-auto">
    <div
      class="tw-flex tw-flex-col tw-flex-wrap tw-items-center tw-justify-start tw-overflow-hidden tw-px-3 tw-py-5 tw-pb-6 sm:tw-px-4"
    >
      <img
        class="tw-mx-auto tw-mb-4 tw-h-20 tw-w-20 tw-overflow-hidden tw-rounded-full tw-shadow-round"
        :src="picture"
        alt=""
      />
      <VText
        :cfg="titleConfig"
        color="black"
        class="clip tw-mb-1 tw-max-w-full tw-text-center"
      >
        {{ entity }}
      </VText>
      <component
        :is="kindComponent"
        class="clip tw-max-w-full tw-text-center"
        >{{ kinds }}</component
      >
      <Sm400Gray
        v-if="countries.length"
        class="clip tw-mt-1 tw-max-w-full tw-text-center"
        >{{ countries }}</Sm400Gray
      >
    </div>
  </VCard>
</template>

<style scoped lang="scss">
.clip {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
</style>
