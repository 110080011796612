<script setup lang="ts">
import IndexMainTitle from '~/components/index/mainTitle.vue'
import { Base400Black } from '~/components/typography'
import VCard from '~/components/ui/VCard.vue'
import VText from '~/components/ui/VText.vue'

import { useGetMediaUrl } from '~/composables/useGetMediaUrl'

import type Lang from '~/types/lang'

type Props = {
  selectedLang: Lang
}
defineProps<Props>()

type SectionsConfigItem = {
  svg: string
  title: string
  description: string
}

const { getMediaUrl } = useGetMediaUrl()
const { t } = useI18n()

const sectionsConfig: SectionsConfigItem[] = [
  {
    description: t('index.description.receiveFeedback'),
    svg: getMediaUrl('/welcomeImages/retours.svg'),
    title: t('index.subtitle.receiveFeedback'),
  },
  {
    description: t('index.description.visibility'),
    svg: getMediaUrl('/welcomeImages/visibilite.svg'),
    title: t('index.subtitle.visibility'),
  },
  {
    description: t('index.description.networking'),
    svg: getMediaUrl('/welcomeImages/reseau.svg'),
    title: t('index.subtitle.networking'),
  },
  {
    description: t('index.description.saveTime'),
    svg: getMediaUrl('/welcomeImages/rocket.svg'),
    title: t('index.subtitle.saveTime'),
  },
]
</script>

<template>
  <div>
    <IndexMainTitle class="tw-mb-6 600:tw-mb-10">
      <span v-if="selectedLang !== 'fr'">{{
        $t('index.title.sendYourMusic.sendYour')
      }}</span>
      <i18n-t v-else keypath="index.title.sendYourMusic.sendYour"
        ><span
          ><br class="sm:tw-hidden" />{{ $t('index.title.sendYourMusic.music')
          }}<br /></span
        ><span>{{ $t('index.title.sendYourMusic.listeningAndResponses') }}</span
        ><span
          ><br class="sm:tw-hidden" />{{
            $t('index.title.sendYourMusic.guaranteed')
          }}</span
        ></i18n-t
      >
    </IndexMainTitle>
    <div
      class="tw-grid tw-grid-cols-1 600:tw-grid-cols-2 600:tw-gap-x-4 md:tw-gap-x-6 lg:tw-grid-cols-4"
    >
      <div
        v-for="({ description, title, svg }, sectionIndex) in sectionsConfig"
        :key="sectionIndex"
        class="tw-mb-6"
      >
        <VCard
          class="card tw-mb-4 tw-flex tw-w-full tw-items-center tw-justify-center md:tw-mb-6"
          padding="0"
        >
          <img :class="`illustration-${sectionIndex + 1}`" :src="svg" alt="" />
        </VCard>
        <VText cfg="sans/24/bold" class="tw-mb-2 tw-block">
          {{ title }}
        </VText>
        <Base400Black>{{ description }}</Base400Black>
      </div>
    </div>
  </div>
</template>

<style scoped>
.card {
  height: 240px;
}

.illustration-1 {
  width: 56%;
  @screen sm {
    width: 48%;
  }
  @screen 600 {
    width: 78%;
  }
  @screen md {
    width: 59%;
  }
  @screen lg {
    width: 69%;
  }
  @screen xl {
    width: 78%;
  }
}
.illustration-2 {
  width: 53%;
  @screen sm {
    width: 46%;
  }
  @screen 600 {
    width: 75%;
  }
  @screen md {
    width: 57%;
  }
  @screen lg {
    width: 64%;
  }
  @screen xl {
    width: 72%;
  }
}
.illustration-3 {
  width: 63%;
  @screen sm {
    width: 55%;
  }
  @screen 600 {
    width: 88%;
  }
  @screen md {
    width: 67%;
  }
  @screen lg {
    width: 75%;
  }
  @screen xl {
    width: 85%;
  }
}
.illustration-4 {
  width: 51%;
  @screen sm {
    width: 45%;
  }
  @screen 600 {
    width: 72%;
  }
  @screen md {
    width: 55%;
  }
  @screen lg {
    width: 62%;
  }
  @screen xl {
    width: 70%;
  }
}
</style>
