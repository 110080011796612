<script setup lang="ts">
import { Base400Black, Sm600Orange } from '~/components/typography'
import VCard from '~/components/ui/VCard.vue'
import VText from '~/components/ui/VText.vue'

import { useGetMediaUrl } from '~/composables/useGetMediaUrl'

import { trackEvent } from '~/helpers/LegacyTrackEvent'

type CardConfig = {
  key: string
}

const cardConfigs: CardConfig[] = [
  { key: 'openForAll' },
  { key: 'allTimingsAllowed' },
  { key: 'curatedProfessionals' },
]

const route = useRoute()
const { t } = useI18n()
const { getMediaUrl } = useGetMediaUrl()

function handleCardClick(key: string): void {
  trackEvent(
    {
      category: 'Landing',
      action: 'Open help article',
      link: t(`index.howItWorksCards.link.${key}`),
      origin: 'How it works / More info link',
    },
    route,
  )
}
</script>

<template>
  <div>
    <div
      class="mainContainer tw-grid tw-w-full tw-grid-cols-1 tw-gap-4 md:tw-mx-auto md:tw-gap-6 lg:tw-grid-cols-3"
    >
      <VCard v-for="{ key } in cardConfigs" :key="key" padding="0">
        <a
          :href="$t(`index.howItWorksCards.link.${key}`)"
          target="_blank"
          class="tw-flex tw-h-full tw-cursor-pointer tw-flex-col tw-flex-wrap tw-items-center tw-justify-between"
          @click="handleCardClick(key)"
        >
          <div class="tw-p-5 tw-pb-0">
            <div
              class="head tw-relative tw-mb-6 tw-h-[120px] tw-w-full sm:tw-h-40 lg:tw-h-[120px] xl:tw-h-40"
            >
              <img
                :src="getMediaUrl(`${key}.svg`)"
                class="tw-h-full tw-object-contain tw-object-center"
              />
            </div>
            <div class="body">
              <VText cfg="sans/24/bold" class="tw-mb-2 tw-block tw-w-full">
                {{ $t(`index.howItWorksCards.title.${key}`) }}
              </VText>
              <Base400Black class="tw-mb-4 tw-block tw-w-full">
                {{ $t(`index.howItWorksCards.text.${key}`) }}
              </Base400Black>
            </div>
          </div>
          <div class="foot tw-w-full tw-bg-gray-100 tw-p-4 tw-text-right">
            <Sm600Orange>{{ $t('common.knowmore') }}</Sm600Orange>
            <i class="fas fa-arrow-right tw-ml-1 tw-text-orange-500"></i>
          </div>
        </a>
      </VCard>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.mainContainer {
  @screen md {
    max-width: var(--grid-6);
  }

  @screen lg {
    max-width: unset;
  }
}
</style>
