<script setup lang="ts">
import { BtnOrange, BtnOutlined } from '~/components/ui/Buttons/'

import { hasSlotContent } from '~/utils/hasSlot'

type Props = {
  outlinedLink?: string
  outlinedTarget?: string
}
type Emits = {
  'filled-click': []
  'outlined-click': []
}
type Slots = {
  filled(): any
  outlined(): any
}

withDefaults(defineProps<Props>(), {
  outlinedLink: undefined,
  outlinedTarget: undefined,
})
const emit = defineEmits<Emits>()
defineSlots<Slots>()
</script>

<template>
  <div class="btnWrapper">
    <BtnOrange
      v-if="hasSlotContent($slots.filled)"
      @click="emit('filled-click')"
    >
      <div class="btnText">
        <slot name="filled" />
      </div>
    </BtnOrange>
    <component
      :is="outlinedLink ? 'a' : 'div'"
      v-if="hasSlotContent($slots.outlined)"
      :href="outlinedLink"
      :target="outlinedTarget"
    >
      <BtnOutlined @click.capture="emit('outlined-click')">
        <div class="btnText">
          <slot name="outlined" />
        </div>
      </BtnOutlined>
    </component>
  </div>
</template>

<style lang="scss" scoped>
.btnWrapper {
  @apply tw-mx-auto tw-grid tw-grid-cols-1 tw-justify-items-center tw-gap-4;

  max-width: var(--page-width);

  @screen md {
    @apply tw-flex tw-flex-row-reverse tw-items-center tw-justify-center tw-gap-0;

    a:last-child,
    button:last-child,
    div:last-child {
      margin-left: 0px !important;
    }

    a:first-child,
    button:first-child,
    div:first-child {
      margin-left: 16px;
    }
  }
}
</style>

<style scoped>
.btnWrapper :deep(.btn),
.btnWrapper :deep(a) {
  width: fit-content;
}
</style>
