<script setup lang="ts">
import IndexBtnWrap from '~/components/index/btnWrap.vue'

import { trackEvent } from '~/helpers/LegacyTrackEvent'

const localePath = useLocalePath()
const route = useRoute()
const { t } = useI18n()

function handleCTAClick() {
  trackEvent(
    {
      category: 'Landing',
      action: 'Open influencer signup',
      origin: 'Signup pros / CTA',
    },
    route,
  )
  return navigateTo(localePath('/influencer/signup/'))
}

function handleOutlinedClick() {
  trackEvent(
    {
      category: 'Landing',
      action: 'Open help article',
      link: t('index.link.whatIsAPro'),
      origin: 'Signup pros / Outlined',
    },
    route,
  )
}
</script>

<template>
  <div class="tw-text-center">
    <IndexBtnWrap
      class="tw-my-6 600:tw-my-10"
      :outlined-link="$t('index.link.whatIsAPro')"
      outlined-target="_new"
      @filled-click="handleCTAClick"
      @outlined-click="handleOutlinedClick"
    >
      <template #filled>{{ $t('index.button.becomeAPro') }}</template>
      <template #outlined>{{ $t('index.button.whatIsAPro') }}</template>
    </IndexBtnWrap>
  </div>
</template>
