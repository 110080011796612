<script setup lang="ts">
import { storeToRefs } from 'pinia'

import {
  currencyConvertHelper,
  getAfterFloats,
} from '~/directives/ConvertToTargetCurrency'

import IndexBtnWrap from '~/components/index/btnWrap.vue'
import CurrencyOrder from '~/components/shared/currency/order.vue'
import CurrencySwapper from '~/components/shared/currency/swapper.vue'
import { Base400Black, Xs400Gray } from '~/components/typography'
import VCard from '~/components/ui/VCard.vue'

import { useGetMediaUrl } from '~/composables/useGetMediaUrl'

import { useBandSignupStore } from '~/stores/bandSignup'
import { useMiscCurrencyStore } from '~/stores/miscCurrency'
import { useMiscResizeStore } from '~/stores/miscResize'

import { trackEvent } from '~/helpers/LegacyTrackEvent'

import { Breakpoints } from '~/enums/breakpoints'
import { symbolLookupTable as currencySymbolLookuptable } from '~/types/currency'

const route = useRoute()
const { getMediaUrl } = useGetMediaUrl()
const { t } = useI18n()

const { SCREEN_WIDTH } = storeToRefs(useMiscResizeStore())
const {
  IS_USING_CONVERSION: IS_USING_CURRENCY_CONVERSION,
  targetCurrency: TARGET_CURRENCY,
} = storeToRefs(useMiscCurrencyStore())

const { SET_DISPLAY: BAND_SIGNUP_SET_DISPLAY } = useBandSignupStore()

const targetCurrencyValue = computed<number>(() => {
  return currencyConvertHelper(2, true)
})

const targetCurrencyFloatingPoints = computed<string>(() =>
  getAfterFloats(targetCurrencyValue.value),
)

const currencyText = computed<string>(() => {
  if (IS_USING_CURRENCY_CONVERSION.value) {
    return `~${Math.floor(targetCurrencyValue.value).toFixed(0)}${t(
      `currency.floatIndicator.${TARGET_CURRENCY.value}`,
    )}${targetCurrencyFloatingPoints.value}${
      currencySymbolLookuptable[TARGET_CURRENCY.value]
    }`
  }

  return `= 2${currencySymbolLookuptable[TARGET_CURRENCY.value]}`
})

function handlePricingCTAClick(): void {
  BAND_SIGNUP_SET_DISPLAY(true)
  trackEvent(
    {
      category: 'Landing',
      action: 'Open band signup dialog',
      origin: 'Pricing / CTA',
    },
    route,
  )
}

function handlePricingOutlinedClick(): void {
  trackEvent(
    {
      category: 'Landing',
      action: 'Open help article',
      link: t('index.link.pricingOutlinedButton'),
      origin: 'Pricing / Outlined',
    },
    route,
  )
}
</script>

<template>
  <div class="tw-text-center">
    <VCard
      class="pricingContainer tw-mb-6 tw-text-center 600:tw-mb-10"
      :style="{
        backgroundImage: `url(${getMediaUrl('homepage/pricing-orange-bg.svg')})`,
      }"
      :padding="SCREEN_WIDTH < Breakpoints.sm600 ? '6' : '10'"
    >
      <div
        class="tw-mb-16 tw-mt-1 tw-flex tw-items-baseline tw-justify-center tw-gap-2 600:tw--mt-3"
      >
        <VText
          cfg="sans/64/bold"
          class="tw-flex tw-items-baseline"
          color="white"
        >
          2 <i class="icon-grooviz groovizBigIcon" />
        </VText>
        <VText cfg="sans/24/bold" class="tw-opacity-75" color="white">
          {{ $t('index.title.perContact') }}
        </VText>
      </div>

      <div class="tw-my-2 tw-flex tw-items-center tw-justify-center">
        <VText cfg="sans/20/bold" class="subtitle">
          <i class="icon-check tw-text-xl sm:tw-text-2xl" />
          <span>2</span>
          <i class="icon-grooviz tw-text-base" />
          <span>=</span>
          <CurrencyOrder class="tw-inline">
            <span :key="TARGET_CURRENCY" class="currencyWrap">
              <span>{{ Math.floor(targetCurrencyValue).toFixed(0) }}</span
              ><span v-if="IS_USING_CURRENCY_CONVERSION" class="floatingPoint"
                >{{ $t(`currency.floatIndicator.${TARGET_CURRENCY}`)
                }}{{ targetCurrencyFloatingPoints }}</span
              >
            </span>
          </CurrencyOrder>
        </VText>
        <CurrencySwapper
          class="swapperWrap"
          :context-menu-move-breakpoint="480"
        />
      </div>
      <div class="tw-mb-6 tw-block 600:tw-mb-10">
        <Base400Black>
          {{ $t('index.text.priceSystem', { currencyText }) }}
        </Base400Black>
        <Xs400Gray
          v-if="IS_USING_CURRENCY_CONVERSION"
          class="tw-mt-2 tw-block"
          >{{ $t('currency.text.context') }}</Xs400Gray
        >
      </div>
      <div class="tw-mb-2 tw-px-8 375:tw-px-0">
        <VText cfg="sans/20/bold" class="subtitle">
          <i class="icon-check tw-text-xl sm:tw-text-2xl" />
          {{ $t('index.text.guaranteedListening') }}
        </VText>
      </div>

      <Base400Black class="tw-mb-6 tw-block 600:tw-mb-10">
        {{ $t('index.text.guaranteedListeningDescription') }}
      </Base400Black>

      <div class="tw-mb-2 tw-px-10 375:tw-px-0">
        <VText cfg="sans/20/bold" class="subtitle">
          <i class="icon-check tw-text-xl sm:tw-text-2xl" />
          {{ $t('index.text.ifNoFeedback') }}
        </VText>
      </div>

      <Base400Black class="tw-block">
        {{ $t('index.text.ifNoFeedbackDescription') }}
      </Base400Black>
    </VCard>

    <IndexBtnWrap
      class="tw-my-6 600:tw-my-10"
      :outlined-link="$t('index.link.pricingOutlinedButton')"
      outlined-target="_new"
      @filled-click="handlePricingCTAClick"
      @outlined-click="handlePricingOutlinedClick"
    >
      <template #filled>{{ $t('index.button.sendMyMusic') }}</template>
      <template #outlined>{{ $t('index.button.moreInfo') }}</template>
    </IndexBtnWrap>
  </div>
</template>

<style lang="scss" scoped>
.subtitle {
  @screen sm {
    @apply tw-text-2xl tw-font-bold;
  }
}
.pricingContainer {
  @apply tw-h-auto tw-w-full;
  background-repeat: no-repeat;
  background-color: white;
  background-position: top -15px left -55px;
  @screen sm {
    background-position: top 0 left -55px;
  }
  @screen 600 {
    @apply tw-mx-auto;
    background-position: top 0 left 0;
    max-width: 536px;
  }
  @screen md {
    max-width: 522px;
  }
  @screen lg {
    max-width: 476px;
  }
  @screen xl {
    max-width: 558px;
  }
}

.icon-check {
  @apply tw-mr-1 tw-text-orange-500;
}

.groovizBigIcon {
  @apply tw-ml-1;
  font-size: 36px;
  @screen sm {
    @apply tw-ml-2 tw-text-5xl;
  }
}

.floatingPoint {
  @apply tw-font-semibold tw-text-black;

  --tw-text-opacity: 0.6;

  font-size: 10px;
  margin-bottom: 2px;

  @screen 375 {
    font-size: 13px;
  }
}

.swapperWrap {
  @apply tw-ml-3 tw-self-end;

  margin-bottom: 5px;
}
</style>
