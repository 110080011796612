<script setup lang="ts">
import { storeToRefs } from 'pinia'

import IndexInfluencersCard from '~/components/index/influencers/card.vue'

import { useMiscResizeStore } from '~/stores/miscResize'

import { trackEvent } from '~/helpers/LegacyTrackEvent'

import { Breakpoints } from '~/enums/breakpoints'

import type { IndexPartialInfluencer } from '~/pages/index/references'
import type { PropType } from 'vue'

type Props = {
  partialInfluencers: IndexPartialInfluencer[]
}
const props = defineProps<Props>()

const route = useRoute()

const { SCREEN_WIDTH } = storeToRefs(useMiscResizeStore())

const hasHandler = ref(false)
const maxOffset = ref(0)
const translateAmount = ref(0)

const hasArrows = computed<boolean>(() => maxOffset.value > 0)

const scrollerTransformStyle = computed(() =>
  SCREEN_WIDTH.value < Breakpoints.lg
    ? {}
    : {
        transform: `translateX(${translateAmount.value}px)`,
      },
)

function resizeEventHandler(): void {
  const child = window.document.getElementById('indexInfluencerReferencesChild')
  const parent = window.document.getElementById(
    'indexInfluencerReferencesParent',
  )

  if (child && parent) {
    maxOffset.value = child.offsetWidth + 74 * 2 - parent.offsetWidth
    if (translateAmount.value > maxOffset.value && maxOffset.value > 0)
      translateAmount.value = maxOffset.value
  } else {
    translateAmount.value = 0
  }
}

onMounted(() => {
  if (hasHandler.value) return
  hasHandler.value = true
  window.addEventListener('resize', resizeEventHandler)
  resizeEventHandler()
})

onBeforeUnmount(() => {
  if (!hasHandler.value) return
  window.removeEventListener('resize', resizeEventHandler)
})

function handleLeftMove(): void {
  if (hasArrows.value) {
    translateAmount.value += 250
    if (translateAmount.value > 0) translateAmount.value = 0
  } else {
    translateAmount.value = 0
  }

  trackEvent(
    {
      category: 'Landing',
      action: 'Carousel arrow click',
      origin: 'References carousel',
      direction: 'Left',
    },
    route,
  )
}

function handleRightMove(): void {
  if (hasArrows.value) {
    translateAmount.value -= 250
    if (translateAmount.value < -maxOffset.value)
      translateAmount.value = -maxOffset.value
  } else {
    translateAmount.value = 0
  }

  trackEvent(
    {
      category: 'Landing',
      action: 'Carousel arrow click',
      origin: 'References carousel',
      direction: 'Right',
    },
    route,
  )
}
</script>

<template>
  <div class="tw-relative tw-w-full">
    <div
      id="indexInfluencerReferencesParent"
      class="outerScroller tw-overflow-y-auto lg:tw-overflow-hidden"
    >
      <i class="fas fa-chevron-left arrow" @click="handleLeftMove" />
      <div
        id="indexInfluencerReferencesChild"
        class="scroller"
        :style="scrollerTransformStyle"
      >
        <IndexInfluencersCard
          v-for="(influencer, influencerIndex) in partialInfluencers"
          :key="influencerIndex"
          :influencer="influencer"
          class="card tw-block tw-w-full tw-shrink-0"
        />
        <div class="filler tw-shrink-0 lg:tw-hidden" />
      </div>
      <i
        class="fas fa-chevron-right arrow tw-hidden lg:tw-block"
        @click="handleRightMove"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.filler {
  width: calc(var(--grid-pad-adjust) - 16px);
}

.outerScroller {
  padding: 0px var(--grid-pad-adjust);

  @screen lg {
    @apply tw-p-0 tw-pl-16;
    margin: 0px var(--grid-pad-adjust);
  }
}

.scroller {
  @apply tw-relative tw-grid tw-w-full tw-grid-flow-col tw-gap-4;
  width: max-content;

  @screen md {
    @apply tw-gap-5;
  }

  @screen lg {
    @apply tw-overflow-visible tw-transition-transform tw-duration-75 tw-ease-in-out;
  }
}

.card {
  width: calc(var(--grid-2) + 16px);
  height: 212px;

  @screen sm {
    width: calc(var(--grid-2) - 16px);
    height: 232px;
  }

  @screen md {
    width: calc(var(--grid-2) - 24px);
  }

  @screen lg {
    width: var(--grid-2);
  }
}

.arrow {
  --arrow-width: 10px;
  --arrow-inner-offset: 64px;
  --arrow-container-width: calc(var(--arrow-width) + var(--arrow-inner-offset));

  @apply tw-z-10 tw-hidden tw-h-auto tw-shrink-0 tw-cursor-pointer tw-items-center tw-justify-end tw-pt-1 tw-text-gray-500;

  @screen lg {
    @apply tw-absolute tw-right-0 tw-top-0 tw-flex;
    width: var(--arrow-container-width);
    height: 100%;
    background: linear-gradient(
      to left,
      theme('colors.gray-100') 48px,
      rgba(249, 249, 249, 0)
    );
  }

  &:last-child {
    margin-right: var(--grid-pad-adjust);
  }

  &:first-child {
    @apply tw-left-0 tw-justify-start;
    background: linear-gradient(
      to left,
      rgba(249, 249, 249, 0),
      theme('colors.gray-100') 48px
    );
    margin-left: var(--grid-pad-adjust);
  }
}
</style>
