import type BlogPost from '~/types/blogPost'
import type { PaginatedApiResponse } from '~/types/PaginatedApiResponse'

export async function fetchBlogPosts(
  locale: Locale['code'],
  fallbackLocale: Locale['code'],
): Promise<BlogPost[]> {
  try {
    const { results }: { results: BlogPost[] } = await $coreFetch.$get<
      PaginatedApiResponse<BlogPost>
    >(`/welcome/blog-post/`, {
      query: {
        limit: 3,
        lang: locale,
        // TODO: fix this
        fallback_lang: fallbackLocale,
      },
    })
    return results
  } catch (_) {
    return []
  }
}
