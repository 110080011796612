<script setup lang="ts">
import VText from '~/components/ui/VText.vue'

import { useGetMediaUrl } from '~/composables/useGetMediaUrl'

const { getMediaUrl } = useGetMediaUrl()
</script>

<template>
  <div>
    <div
      class="tw-relative tw-grid tw-grid-cols-1 tw-items-center tw-justify-between tw-gap-6 tw-overflow-hidden lg:tw-grid-cols-[256px_1fr] lg:tw-gap-0"
    >
      <VText
        cfg="sans/24/bold"
        class="tw-inline-block tw-text-center tw-opacity-50 lg:tw-shrink-0 lg:tw-text-left"
        >{{ $t('components.index.PartnerList.title') }}</VText
      >
      <div
        class="tw-grid tw-grid-cols-1 tw-justify-items-center tw-gap-4 sm:tw-grid-cols-2 600:tw-gap-y-6 md:tw-gap-x-6 lg:tw-grid-cols-4 lg:tw-gap-0"
      >
        <img alt="CD-Baby Logo" :src="getMediaUrl('Logo-CDBaby.svg')" />
        <img
          alt="Tunecore Logo"
          :src="getMediaUrl('Logo-Tunecore.svg')"
          class="tw-py-[11px]"
        />
        <img
          alt="Soundcloud Logo"
          :src="getMediaUrl('Logo-Soundcloud.svg')"
          class="tw-py-[14px]"
        />
        <img
          alt="Patreon Logo"
          :src="getMediaUrl('Logo-Patreon.svg')"
          class="tw-py-[13px]"
        />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
img {
  @apply tw-flex tw-h-10 tw-items-center tw-justify-center tw-object-contain tw-opacity-50;
}
</style>
