<script setup lang="ts">
import RatioContainer from '~/components/shared/ratioContainer.vue'
import { Base400Black } from '~/components/typography'
import { BtnOrange } from '~/components/ui/Buttons/'
import VText from '~/components/ui/VText.vue'

import { useGetMediaUrl } from '~/composables/useGetMediaUrl'

import { trackEvent } from '~/helpers/LegacyTrackEvent'

type SectionsConfigItem = {
  buttonLink: string
  buttonText: string
  descriptionList: string[]
  imageSource: string
  title: string
}

const route = useRoute()
const { getMediaUrl } = useGetMediaUrl()
const { t } = useI18n()

const sectionsConfig: SectionsConfigItem[] = [
  {
    buttonLink: 'https://www.groover.co/about/',
    buttonText: t('index.button.moreInfo'),
    descriptionList: [t('index.text.missionDescription')],
    imageSource: `Alvin--WebP.webp`,
    title: t('index.title.ourMission'),
  },
  {
    buttonLink: 'https://www.welcometothejungle.com/en/companies/groover',
    buttonText: t('index.button.joinUs'),
    descriptionList: [t('index.text.valuesDescription1')],
    imageSource: `Team--WebP.webp`,
    title: t('index.title.ourValues'),
  },
]

function handleClick({ buttonLink }: { buttonLink: string }) {
  trackEvent(
    {
      category: 'Landing',
      action: 'Open page',
      link: buttonLink,
      origin: 'The team',
    },
    route,
  )
}
</script>

<template>
  <div
    id="ignoreFontUpscale"
    class="ignoreFontUpscale tw-text-center 600:tw-text-left"
  >
    <div
      class="tw-flex tw-flex-col tw-space-y-6 600:tw-grid 600:tw-grid-cols-2 600:tw-gap-4 600:tw-space-y-0 md:tw-gap-6"
    >
      <div
        v-for="(
          { buttonLink, buttonText, descriptionList, imageSource, title },
          sectionIndex
        ) in sectionsConfig"
        :key="sectionIndex"
      >
        <RatioContainer
          :x-aspect="3"
          :y-aspect="2"
          class="tw-mb-4 tw-overflow-hidden"
        >
          <img
            class="tw-h-auto tw-w-full tw-rounded-sm tw-shadow-heavy"
            :src="getMediaUrl(imageSource)"
            alt=""
          />
        </RatioContainer>
        <VText cfg="sans/24/bold">
          {{ title }}
        </VText>
        <div class="tw-flex tw-flex-col"></div>
        <div class="tw-mb-4 tw-mt-2 600:tw-leading-5 md:tw-leading-5">
          <Base400Black
            v-for="(description, descriptionIndex) in descriptionList"
            :key="descriptionIndex"
            class="tw-block"
          >
            <span
              v-if="!description.includes('index.text.valuesDescription')"
              >{{ description }}</span
            >
          </Base400Black>
        </div>

        <a
          :href="buttonLink"
          target="_new"
          class="tw-mt-auto"
          @click="handleClick({ buttonLink })"
        >
          <div class="tw-mx-auto tw-w-fit 600:tw-mx-0">
            <BtnOrange class="375:tw-text-base">{{ buttonText }}</BtnOrange>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>
