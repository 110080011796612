<script setup lang="ts">
import { storeToRefs } from 'pinia'

import IndexBtnWrap from '~/components/index/btnWrap.vue'
import IndexInfluencerCard from '~/components/index/influencers/card.vue'
import { Base400Black, Base700Black } from '~/components/typography/'

import { useBandSignupStore } from '~/stores/bandSignup'
import { useInfluencersStore } from '~/stores/influencers'
import { useMiscResizeStore } from '~/stores/miscResize'

import { trackEvent } from '~/helpers/LegacyTrackEvent'

import { Breakpoints } from '~/enums/breakpoints'

import type { StatsV3Influencer } from '~/types/influencer'

type Config = {
  influencers: StatsV3Influencer[]
  title: string
  key: string
}

type Props = {
  influencerCount: number
  genreToInfluencerIdMap?: Record<string, number[]>
}
const props = withDefaults(defineProps<Props>(), {
  genreToInfluencerIdMap: () => ({}),
})

const route = useRoute()
const { t } = useI18n()

const hasHandler = ref(false)
const maxOffset = ref(0)
const selectedConfigIndex = ref(0)
const translateAmount = ref(0)

const { SCREEN_WIDTH } = storeToRefs(useMiscResizeStore())
const { GET_BY_IDS_SORTED: GET_INFLUENCERS_SORTED_BY_IDS } =
  useInfluencersStore()
const { SET_DISPLAY: BAND_SIGNUP_SET_DISPLAY } = useBandSignupStore()

const influencerRows = computed<number>(() =>
  SCREEN_WIDTH.value < Breakpoints.sm600
    ? 8
    : SCREEN_WIDTH.value < Breakpoints.lg
      ? 4
      : 3,
)

const influencerColumns = computed<number>(() =>
  SCREEN_WIDTH.value < Breakpoints.sm600
    ? 2
    : SCREEN_WIDTH.value < Breakpoints.lg
      ? 4
      : 6,
)

const maxInfluencerCount = computed<number>(
  () => influencerRows.value * influencerColumns.value,
)

const hasArrows = computed<boolean>(() => maxOffset.value > 0)

const sortOrderByKey: string[] = [
  'pop',
  'hip-hop-rap',
  'electronic',
  'rock-punk',
  'rb-soul',
  'folk-acoustic',
  'chanson',
  'world',
  'metal',
  'jazz',
  'classique-instrumental',
  'reggae',
]
const configs = computed<Config[]>(() =>
  Object.entries(props.genreToInfluencerIdMap)
    .reduce<Config[]>((accumulator, [genreKey, influencerIds]) => {
      accumulator.push({
        title: t(`parentTags.subgenre.${genreKey}`),
        influencers: GET_INFLUENCERS_SORTED_BY_IDS(influencerIds),
        key: genreKey,
      })
      return accumulator
    }, [])
    .toSorted((a, b) => {
      const aIndex = sortOrderByKey.indexOf(a.key)
      const bIndex = sortOrderByKey.indexOf(b.key)

      return aIndex >= 0 && bIndex >= 0
        ? sortOrderByKey.length - bIndex - (sortOrderByKey.length - aIndex)
        : aIndex === -1
          ? 1
          : -1
    }),
)

const selectedConfigInfluencers = computed<StatsV3Influencer[]>(() => {
  if (configs.value[selectedConfigIndex.value]) {
    return (configs.value[selectedConfigIndex.value].influencers ?? []).slice(
      0,
      maxInfluencerCount.value,
    )
  } else {
    return []
  }
})

const scrollerTransformStyle = computed(() =>
  SCREEN_WIDTH.value < Breakpoints.lg
    ? {}
    : {
        transform: `translateX(${translateAmount.value}px)`,
      },
)

function handleCardClick(influencer: StatsV3Influencer): void {
  BAND_SIGNUP_SET_DISPLAY(true)
  trackEvent(
    {
      category: 'Landing',
      action: 'Open band signup dialog',
      origin: 'Influencer list / Card',
      influencerName: influencer.entity,
      influencerId: influencer.id,
    },
    route,
  )
}

function handleFilledCTAClick(): void {
  BAND_SIGNUP_SET_DISPLAY(true)
  trackEvent(
    {
      category: 'Landing',
      action: 'Open band signup dialog',
      origin: 'Influencer list / CTA',
    },
    route,
  )
}

function handledOutlinedClick(): void {
  trackEvent(
    {
      category: 'Landing',
      action: 'Open help article',
      link: t('index.link.whatIsAPro'),
      origin: 'Influencer list / Outlined',
    },
    route,
  )
}

function handleLeftMove(): void {
  if (hasArrows.value) {
    translateAmount.value += 150
    if (translateAmount.value > 0) translateAmount.value = 0
  } else {
    translateAmount.value = 0
  }

  trackEvent(
    {
      category: 'Landing',
      action: 'Carousel arrow click',
      origin: 'Influencers carousel',
      direction: 'Right',
    },
    route,
  )
}

function handleRightMove(): void {
  if (hasArrows.value) {
    translateAmount.value -= 150
    if (translateAmount.value < -maxOffset.value)
      translateAmount.value = -maxOffset.value
  } else {
    translateAmount.value = 0
  }

  trackEvent(
    {
      category: 'Landing',
      action: 'Carousel arrow click',
      origin: 'Influencers carousel',
      direction: 'Right',
    },
    route,
  )
}

function handleSelectGenre(index: number): void {
  selectedConfigIndex.value = index
  trackEvent(
    {
      category: 'Landing',
      action: 'Carousel section click',
      origin: 'Influencers carousel',
      sectionName: configs.value?.[index]?.title,
    },
    route,
  )
}

function resizeEventHandler(): void {
  const child = window.document.getElementById('indexInfluencerChild')
  const parent = window.document.getElementById('indexInfluencerParent')

  if (child && parent) {
    maxOffset.value = child.offsetWidth + 74 * 2 - parent.offsetWidth
    if (translateAmount.value > maxOffset.value && maxOffset.value > 0)
      translateAmount.value = maxOffset.value
  } else {
    translateAmount.value = 0
  }
}

function getSelectionComponent(
  index: number,
): typeof Base700Black | typeof Base400Black {
  if (index === selectedConfigIndex.value) return markRaw(Base700Black)
  else return markRaw(Base400Black)
}

onMounted(() => {
  if (hasHandler.value) return
  hasHandler.value = true
  window.addEventListener('resize', resizeEventHandler)
  resizeEventHandler()
})

onBeforeUnmount(() => {
  if (!hasHandler.value) return
  window.removeEventListener('resize', resizeEventHandler)
})
</script>

<template>
  <div>
    <div id="indexInfluencerParent" class="scrollerOuterWrap">
      <i
        v-if="SCREEN_WIDTH >= Breakpoints.lg"
        :class="{ 'tw-opacity-100': hasArrows }"
        class="fas fa-chevron-left arrow"
        @click="handleLeftMove"
      />
      <div
        id="indexInfluencerChild"
        class="scroller"
        :style="scrollerTransformStyle"
      >
        <component
          :is="getSelectionComponent(index)"
          v-for="(config, index) in configs"
          :key="config.key"
          class="stretchElem tw-relative tw-cursor-pointer tw-pb-4"
          :class="{ isSelected: index === selectedConfigIndex }"
          @click="handleSelectGenre(index)"
        >
          {{ config.title }}
        </component>
        <div v-if="SCREEN_WIDTH < Breakpoints.lg" class="filler" />
      </div>
      <i
        v-if="SCREEN_WIDTH >= Breakpoints.lg"
        :class="{ 'tw-opacity-100': hasArrows }"
        class="fas fa-chevron-right arrow"
        @click="handleRightMove"
      />
    </div>
    <transition name="fade" mode="out-in">
      <div
        :key="selectedConfigIndex"
        class="mainGrid tw-grid tw-grid-cols-2 tw-gap-4 600:tw-grid-cols-4 md:tw-gap-6 lg:tw-grid-cols-6"
      >
        <IndexInfluencerCard
          v-for="influencer in selectedConfigInfluencers"
          :key="influencer.id"
          :influencer="influencer"
          class="cardHeightFix tw-cursor-pointer"
          @click="handleCardClick(influencer)"
        />
      </div>
    </transition>
    <IndexBtnWrap
      outlined-target="_blank"
      :outlined-link="$t('index.link.whatIsAPro')"
      class="tw-mt-6 600:tw-mt-10"
      @outlined-click="handledOutlinedClick"
      @filled-click="handleFilledCTAClick"
    >
      <template #filled>
        {{ $t('index.btn.discoverNsInfs', { influencerCount }) }}
      </template>
      <template #outlined>{{ $t('index.button.whatIsAPro') }}</template>
    </IndexBtnWrap>
  </div>
</template>

<style scoped lang="scss">
.mainGrid {
  padding: 0px var(--grid-pad-adjust);
}

.scrollerOuterWrap {
  --arrow-width: 10px;
  --arrow-inner-offset: 64px;
  --arrow-container-width: calc(var(--arrow-width) + var(--arrow-inner-offset));

  @apply tw-relative tw-mb-6 tw-border-0 tw-border-b tw-border-solid tw-border-gray-300;

  @screen lg {
    @apply tw-overflow-hidden tw-p-0;
    margin-left: var(--grid-pad-adjust);
    margin-right: var(--grid-pad-adjust);
    padding-left: var(--arrow-container-width);
    padding-right: var(--arrow-container-width);
  }

  .scroller {
    @apply tw-grid tw-w-auto tw-grid-flow-col tw-gap-8 tw-overflow-x-auto;
    padding: 0px var(--grid-pad-adjust);

    @screen 600 {
      @apply tw-gap-12;
    }

    @screen lg {
      // tw-gap-16 = --arrow-inner-offset
      @apply tw-gap-16 tw-overflow-visible tw-p-0 tw-transition-transform tw-duration-75 tw-ease-in-out;
      width: max-content;
    }

    .filler {
      //Prevent's grid skipping
      min-width: 1px;
      //24px is current grid gap
      width: calc(var(--grid-pad-adjust) - 24px);
      display: block;
    }

    .stretchElem {
      width: auto;
      white-space: nowrap;

      &:before {
        --lineOffset: 12px;
        @apply tw-absolute tw-bottom-0 tw-h-1 tw-rounded-full tw-bg-black tw-opacity-0 tw-transition-opacity tw-duration-150 tw-ease-in-out;
        width: calc(100% + (var(--lineOffset) * 2));
        left: calc(var(--lineOffset) * -1);
        content: '';

        @screen 600 {
          --lineOffset: 24px;
        }
      }

      &.isSelected:before {
        @apply tw-opacity-100;
      }
    }
  }

  .arrow {
    @apply tw-z-10 tw-flex tw-h-auto tw-cursor-pointer tw-items-start tw-justify-end tw-pt-1 tw-text-gray-500;

    @screen lg {
      @apply tw-absolute tw-right-0 tw-top-0 tw-flex;
      width: var(--arrow-container-width);
      height: 100%;
      background: linear-gradient(
        to left,
        theme('colors.gray-100') 48px,
        rgba(249, 249, 249, 0)
      );
    }

    &:last-child {
      padding-right: var(--grid-pad-adjust);
    }

    &:first-child {
      @apply tw-left-0 tw-justify-start;
      background: linear-gradient(
        to left,
        rgba(249, 249, 249, 0),
        theme('colors.gray-100') 48px
      );
      padding-left: var(--grid-pad-adjust);
    }
  }
}

.cardHeightFix {
  min-height: 236px;
  height: auto;
}
</style>
