import type { PaginatedApiResponse } from '~/types/PaginatedApiResponse'
import type SuccessStory from '~/types/successStory'

export function fetchSuccessStories(
  locale: Locale['code'],
  fallbackLocale: Locale['code'],
): Promise<SuccessStory[]> {
  return new Promise((resolve) => {
    $coreFetch
      .$get<PaginatedApiResponse<SuccessStory>>(`/welcome/success-story/`, {
        query: {
          limit: 3,
          lang: locale,
          fallback_lang: fallbackLocale,
        },
      })
      .then(({ results }: { results: SuccessStory[] }) => {
        resolve(results ?? [])
      })
      .catch(() => {
        resolve([])
      })
  })

  // return new Promise((resolve) => {
  //   $coreFetch.$get(`/welcome/success-story/?limit=3&${evalLangFromReq.bind(this)()}`)
  //     .then(({ results }: { results: SuccessStory[] }) => {
  //       resolve(results ?? [])
  //     })
  //     .catch(() => {
  //       resolve([])
  //     })
  // })
}
