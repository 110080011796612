<script setup lang="ts">
import { storeToRefs } from 'pinia'

import { BtnOrange } from '~/components/ui/Buttons/'
import VText from '~/components/ui/VText.vue'

import { useGetMediaUrl } from '~/composables/useGetMediaUrl'

import { useBandSignupStore } from '~/stores/bandSignup'
import { useMiscResizeStore } from '~/stores/miscResize'

import { trackEvent } from '~/helpers/LegacyTrackEvent'

import { Breakpoints } from '~/enums/breakpoints'

import type { PropConfigType } from '~/components/ui/VText.vue'

const route = useRoute()
const { getMediaUrl } = useGetMediaUrl()

const { SCREEN_WIDTH } = storeToRefs(useMiscResizeStore())
const { SET_DISPLAY: OPEN_SIGNUP } = useBandSignupStore()

const headingTextCfg = computed<PropConfigType>(() => {
  const size =
    SCREEN_WIDTH.value <= Breakpoints.sm
      ? 32
      : SCREEN_WIDTH.value <= Breakpoints.md
        ? 40
        : 48

  return `sans/${size}/bold` as const
})

function handleCTAClick(): void {
  OPEN_SIGNUP(true)
  trackEvent(
    {
      category: 'Landing',
      action: 'Open band signup dialog',
      origin: 'Introduction / CTA',
    },
    route,
  )
}

const backgroundImage = getMediaUrl('homepage/intro-desktop.svg')
</script>

<template>
  <div>
    <div class="tw-mb-6 sm:tw-mb-12 md:tw-mb-20">
      <div class="gridColumns md:tw-grid md:tw-grid-cols-2 md:tw-gap-5">
        <div>
          <VText
            :cfg="headingTextCfg"
            class="title preWrap tw-mb-4 tw-mt-6 tw-block tw-text-center sm:tw-mt-10 600:tw-text-left md:tw-mt-20 xl:tw-mt-24"
            ><i18n-t
              keypath="index.introduction.promoteYourMusic.title.firstLine"
            >
              <span class="tw-block">{{
                $t(`index.introduction.promoteYourMusic.title.middleLine`)
              }}</span
              ><span>{{
                $t(`index.introduction.promoteYourMusic.title.lastLine`)
              }}</span>
            </i18n-t>
          </VText>
          <VText
            cfg="sans/16/normal"
            class="tw-m-auto tw-mb-6 tw-block tw-max-w-[326px] tw-text-center 600:tw-mx-0 600:tw-text-left md:tw-max-w-[339px] xl:tw-max-w-[376px]"
            >{{
              $t('index.introduction.promoteYourMusic.text.introduction')
            }}</VText
          >
          <div class="buttonWrapper tw-m-auto 600:tw-m-0">
            <BtnOrange @click="handleCTAClick">
              {{ $t(`index.introduction.promoteYourMusic.button.sendMyMusic`) }}
            </BtnOrange>
          </div>
        </div>
        <div class="imageDesktopContainer tw-hidden 600:tw-block">
          <div
            class="imageDesktop"
            :style="{ backgroundImage: `url('${backgroundImage}')` }"
          />
        </div>
      </div>
      <div class="imageMobileContainer tw-relative tw-w-full 600:tw-hidden">
        <img
          class="imageMobile"
          :src="getMediaUrl('homepage/intro-mobile.svg')"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.title span {
  @apply tw-font-sans;
}

.gridColumns {
  @screen md {
    grid-template-columns: 8fr 4fr;
  }
}

.buttonWrapper {
  width: 184px;
  @screen 375 {
    width: fit-content;

    .btn {
      @apply tw-w-auto;
    }
  }

  .btn {
    @apply tw-w-full;
  }
}

.imageMobileContainer {
  height: 90vw;

  @screen sm {
    height: 63vw;
  }

  .imageMobile {
    @apply tw-absolute tw-max-w-none;

    top: -102px;
    right: -25px;
    width: 200vw;
    height: auto;
    z-index: -1;
    @screen sm {
      top: -126px;
      right: -33px;
      width: 146vw;
    }
  }
}

.imageDesktopContainer {
  @apply tw-absolute tw-right-0 tw-top-0;
  .imageDesktop {
    @apply tw-absolute tw-right-0 tw-top-0 tw-w-screen tw-bg-no-repeat;
    min-height: 550px;
    z-index: -1;
    @screen 600 {
      background-size: 740px;
      background-position: top 0 right -220px;
    }
    @screen md {
      background-position: top -11px right -255px;
    }
    @screen lg {
      min-height: 610px;
      background-position: top -11px right 0;
    }
    @screen xl {
      background-size: 880px;
      min-height: 720px;
    }
  }
}
</style>
