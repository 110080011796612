<script setup lang="ts">
import { getInfluencerCount } from '~/pages/index/influencerCount'
import { getLandingPageInfluencers } from '~/pages/index/influencers'
import { getPartialInfluencers } from '~/pages/index/references'
import { getActivePinia } from 'pinia'

import HomepageBlog from '~/components/homepage/blog.vue'
import HomepageSuccessStory from '~/components/homepage/successStory.vue'
import IndexBtnWrap from '~/components/index/btnWrap.vue'
import HowItWorksCards from '~/components/index/howItWorksCards.vue'
import IndexInfluencer from '~/components/index/influencers.vue'
import IndexIntroduction from '~/components/index/introduction.vue'
import IndexMainTitle from '~/components/index/mainTitle.vue'
import IndexOurValues from '~/components/index/ourValues.vue'
import IndexPartnerList from '~/components/index/PartnerList.vue'
import IndexPricing from '~/components/index/pricing.vue'
import IndexReferences from '~/components/index/references.vue'
import IndexSignupPros from '~/components/index/signupPros.vue'
import IndexTheTeam from '~/components/index/theTeam.vue'
import IndexVideoPresentation from '~/components/index/videoPresentation.vue'
import SeoFooter from '~/components/seo/footer/index.vue'
import PageFooter from '~/components/shared/footer/index.vue'

import { useGrowthBook } from '~/composables/useGrowthBook'

import { useBandSignupStore } from '~/stores/bandSignup'
import { useMiscCurrencyStore } from '~/stores/miscCurrency'
import { useTagStore } from '~/stores/tag'
import { useUserStore } from '~/stores/user'

import { trackEvent } from '~/helpers/LegacyTrackEvent'
import { provideFetchSeoGenericListHOF } from '~/helpers/seo/seo-generic-list'

import { fetchBlogPosts as getBlogPosts } from '~/api-core/Welcome/FetchBlogPosts'
import { fetchSuccessStories as getSuccessStories } from '~/api-core/Welcome/FetchSuccessStories'

import type { IndexPartialInfluencer } from '~/pages/index/references'
import type BlogPost from '~/types/blogPost'
import type Lang from '~/types/lang'
import type { SeoListing } from '~/types/seoList'
import type SuccessStories from '~/types/successStory'

const { t } = useI18n()
const route = useRoute()
const { locale, fallbackLocale } = useI18n()
const { $sentry } = useNuxtApp()
const { isFeatureOnForGroup } = useGrowthBook()

onMounted(() => {
  // Start AB test 'tracking-landing-page'
  // Triggers GrowthBook's tracking callback. We don't need the results.
  isFeatureOnForGroup('tracking-landing-page', 'control')
  // End AB test 'tracking-landing-page'
})

const [
  { data: blogPosts },
  { data: successStories },
  { data: landingPageInfluencers },
  { data: influencerCount },
  { data: partialInfluencers },
  { data: seoListing },
] = await Promise.all([
  useAsyncData<BlogPost[]>(
    'blogPosts',
    () => getBlogPosts(locale.value, fallbackLocale.value.toString()),
    { default: () => [] },
  ),
  useAsyncData<SuccessStories[]>(
    'successStories',
    () => getSuccessStories(locale.value, fallbackLocale.value.toString()),
    { default: () => [] },
  ),
  useAsyncData<Record<string, number[]>>(
    'LandingPageInfluencers',
    () =>
      getLandingPageInfluencers(locale.value, fallbackLocale.value.toString()),
    { default: () => ({}) },
  ),
  useAsyncData<number>('InfluencerCount', () => getInfluencerCount(), {
    default: () => 800,
  }),
  useAsyncData<IndexPartialInfluencer[]>(
    'PartialInfluencers',
    () => getPartialInfluencers(locale.value, fallbackLocale.value.toString()),
    { default: () => [] },
  ),
  useAsyncData<SeoListing[]>(
    'seoListings',
    () => provideFetchSeoGenericListHOF($coreFetch, $sentry)(),
    { default: () => [] },
  ),
])

definePageMeta({
  middleware() {
    const { $localePath } = useNuxtApp()
    const userStore = useUserStore(getActivePinia())

    if (userStore.IS_INCOMPLETE_USER) return
    if (userStore.IS_INF)
      return navigateTo($localePath('/influencer/dashboard/'))
    if (userStore.IS_BAND) return navigateTo($localePath('/band/homepage/'))
  },
})

const title = t('metaTags.index.title')
const description = t('metaTags.index.description')
useHead({
  title,
  meta: [
    {
      key: 'description',
      name: 'description',
      content: description,
    },
    {
      key: 'og:title',
      property: 'og:title',
      content: title,
    },
    {
      key: 'og:image',
      property: 'og:image',
      content: 'https://groover.co/media/groover-home.jpg',
    },
    {
      key: 'og:description',
      property: 'og:description',
      content: description,
    },
  ],
  script: [
    {
      type: 'application/ld+json',
      // TODO: review this - found this workaround replacement for "json" property on stackoverflow https://stackoverflow.com/questions/70444900/how-to-include-json-ld-script-in-nuxt-3
      innerHTML: JSON.stringify({
        '@context': 'https://schema.org',
        '@type': 'Organization',
        url: 'http://groover.co/',
        logo: 'https://groover.co/media/Logo.svg',
      }),
    },
    {
      type: 'application/ld+json',
      innerHTML: JSON.stringify({
        '@context': 'http://schema.org',
        '@type': 'WebApplication',
        name: 'Groover',
        url: 'https://groover.co/',
        description: t('index.introduction.promoteYourMusic.text.introduction'),
        applicationCategory: 'Marketplace',
        softwareHelp: {
          '@type': 'CreativeWork',
          url: 'https://help.groover.co/',
        },
      }),
    },
    { src: 'https://accounts.google.com/gsi/client' },
  ],
})

const { FETCH: FETCH_TAGS } = useTagStore()
const miscCurrencyStore = useMiscCurrencyStore()
const backupCurrencyCookie = useCookie('backupCurrency')

callOnce('FETCH_TAGS', FETCH_TAGS)
useLazyAsyncData(() => miscCurrencyStore.INIT(backupCurrencyCookie))

const { SET_DISPLAY: BAND_SIGNUP_SET_DISPLAY } = useBandSignupStore()

function handleOurValuesCTAClick(): void {
  BAND_SIGNUP_SET_DISPLAY(true)
  trackEvent(
    {
      category: 'Landing',
      action: 'Open band signup dialog',
      origin: 'Our values / CTA',
    },
    route,
  )
}

function handleOurValuesOutlinedClick(): void {
  document.getElementById('howitworks')?.scrollIntoView({
    behavior: 'smooth',
  })
  trackEvent(
    {
      category: 'Landing',
      action: 'Scroll to How it works',
      origin: 'Our values / Outlined',
    },
    route,
  )
}

function handleSuccessStoriesCTAClick(): void {
  BAND_SIGNUP_SET_DISPLAY(true)
  trackEvent(
    {
      category: 'Landing',
      action: 'Open band signup dialog',
      origin: 'Success stories / CTA',
    },
    route,
  )
}

function handledSuccessStoriesOutlinedClick(): void {
  trackEvent(
    {
      category: 'Landing',
      action: 'Open blog category',
      label: 'All success stories',
      link: t('index.link.allSuccessStories'),
      origin: 'Success stories / Outlined',
    },
    route,
  )
}

function handleHowItWorksCTAClick(): void {
  BAND_SIGNUP_SET_DISPLAY(true)
  trackEvent(
    {
      category: 'Landing',
      action: 'Open band signup dialog',
      origin: 'How it works / CTA',
    },
    route,
  )
}

function handleBlogCTAClick(): void {
  BAND_SIGNUP_SET_DISPLAY(true)
  trackEvent(
    {
      category: 'Landing',
      action: 'Open band signup dialog',
      origin: 'Blog / CTA',
    },
    route,
  )
}

function handleReferencesCTAClick(): void {
  BAND_SIGNUP_SET_DISPLAY(true)
  trackEvent(
    {
      category: 'Landing',
      action: 'Open band signup dialog',
      origin: 'References / CTA',
    },
    route,
  )
}

function handleBlogOutlinedClick(): void {
  trackEvent(
    {
      category: 'Landing',
      action: 'Open blog page',
      link: 'https://blog.groover.co/',
      origin: 'Blog section',
    },
    route,
  )
}
</script>

<template>
  <div id="ignoreFontUpscale" class="indexWrapper ignoreFontUpscale">
    <IndexIntroduction class="mainParentContainer" />
    <div
      class="ourValuesContainer offsettedBackground"
      :class="{ english: locale !== 'fr' }"
    >
      <IndexOurValues
        class="mainParentContainer"
        :selected-lang="locale as Lang"
      />
    </div>
    <IndexBtnWrap
      class="btnWrapTopSpacing bottomSpacing"
      @filled-click="handleOurValuesCTAClick"
      @outlined-click="handleOurValuesOutlinedClick"
    >
      <template #filled>{{ $t('index.button.sendMyMusic') }}</template>
      <template #outlined>{{ $t('index.button.howItWorks') }}</template>
    </IndexBtnWrap>
    <IndexMainTitle id="mediasAndPros" class="tw-pb-10">
      <i18n-t keypath="index.title.influencers">
        <template #lineBreak><br /></template>
        <template #lineBreakHiddenSm><br class="sm:tw-hidden" /></template>
        <template #lineBreakHiddenLg><br class="lg:tw-hidden" /></template>
      </i18n-t>
    </IndexMainTitle>
    <IndexInfluencer
      :genre-to-influencer-id-map="landingPageInfluencers"
      :influencer-count="influencerCount"
      class="bottomSpacing"
    />
    <IndexPartnerList class="bottomSpacing mainParentContainer" />
    <IndexMainTitle id="howitworks" class="titleBottomSpacing">
      <i18n-t keypath="index.title.howItWorks">
        <template #lineBreak>
          <br class="600:tw-hidden" />
        </template>
      </i18n-t>
    </IndexMainTitle>
    <div class="videoPresentationContainer offsettedBackground">
      <IndexVideoPresentation />
    </div>
    <HowItWorksCards
      class="adjustBg adjustToPagePadding tw-py-6 md:tw-pt-10 lg:tw-py-10"
    />
    <IndexBtnWrap
      class="btnWrapTopSpacing bottomSpacing"
      @filled-click="handleHowItWorksCTAClick"
    >
      <template #filled>{{ $t('index.btn.discoverGroover') }}</template>
    </IndexBtnWrap>
    <div id="landingPricing" class="upDownSpacer">
      <IndexMainTitle class="tw-pb-6 sm:tw-pb-10">
        {{ $t('index.title.pricing') }}<br class="md:tw-hidden" />
        {{ $t('index.title.pricingLine2') }}
      </IndexMainTitle>
      <IndexPricing class="mainParentContainer" />
    </div>
    <div class="bottomSpacing">
      <IndexMainTitle class="titleBottomSpacing">
        <i18n-t v-if="locale === 'fr'" keypath="index.title.references">
          <template #lineBreakHidden600><br class="600:tw-hidden" /></template>
          <template #lineBreakHiddenBlock600
            ><br class="tw-hidden 600:tw-block"
          /></template>
          <template #lineBreakHiddenLg><br class="lg:tw-hidden" /></template>
          <template #lineBreakHiddenMdBlockLg
            ><br class="md:tw-hidden lg:tw-block"
          /></template>
        </i18n-t>
        <i18n-t v-else keypath="index.title.references">
          <template #lineBreakHiddenMd><br class="md:tw-hidden" /></template>
          <template #lineBreak><br /></template>
          <template #lineBreakHiddenSm><br class="sm:tw-hidden" /></template>
          <template #lineBreakHiddenLg><br class="lg:tw-hidden" /></template>
        </i18n-t>
      </IndexMainTitle>
      <IndexReferences :partial-influencers="partialInfluencers" />
      <IndexBtnWrap
        class="btnWrapTopSpacing"
        @filled-click="handleReferencesCTAClick"
      >
        <template #filled>{{ $t('index.btn.promoteMyMusic') }}</template>
      </IndexBtnWrap>
    </div>
    <div v-if="successStories.length">
      <IndexMainTitle class="tw-mb-5">
        <i18n-t keypath="index.title.successStories">
          <template #lineBreakHiddenSm><br class="sm:tw-hidden" /></template>
          <template #lineBreakHiddenBlockSm
            ><br class="tw-hidden sm:tw-block"
          /></template>
        </i18n-t>
      </IndexMainTitle>
      <div class="successStoriesContainer offsettedBackground">
        <HomepageSuccessStory
          v-if="successStories.length"
          :stories="successStories"
        />
      </div>
      <IndexBtnWrap
        class="btnWrapTopSpacing bottomSpacing"
        :outlined-link="$t('index.link.allSuccessStories')"
        outlined-target="_new"
        @filled-click="handleSuccessStoriesCTAClick"
        @outlined-click="handledSuccessStoriesOutlinedClick"
      >
        <template #filled>{{ $t('index.btn.promoteMyMusic') }}</template>
        <template #outlined>{{ $t('index.btn.allSuccessStories') }}</template>
      </IndexBtnWrap>
    </div>
    <div class="upDownSpacer">
      <IndexMainTitle class="tw-pb-6 sm:tw-pb-10">
        {{ $t('index.title.theTeamLine1') }}
      </IndexMainTitle>
      <IndexTheTeam class="mainParentContainer" />
    </div>
    <div v-if="blogPosts.length">
      <IndexMainTitle class="tw-mb-5">
        <i18n-t keypath="index.title.blogPosts">
          <template #lineBreak><br /></template>
          <template #lineBreakHiddenLg><br class="lg:tw-hidden" /></template>
        </i18n-t>
      </IndexMainTitle>
      <div class="blogContainer offsettedBackground">
        <HomepageBlog
          :posts="blogPosts"
          :has-additional-text="false"
          :has-additional-card="false"
        />
      </div>
      <IndexBtnWrap
        class="btnWrapTopSpacing bottomSpacing"
        outlined-link="http://blog.groover.co/"
        outlined-target="_blank"
        @filled-click="handleBlogCTAClick"
        @outlined-click="handleBlogOutlinedClick"
      >
        <template #filled>{{ $t('index.btn.discoverGroover') }}</template>
        <template #outlined>{{ $t('index.btn.visitTheGrooverBlog') }}</template>
      </IndexBtnWrap>
    </div>
    <div class="upDownSpacer">
      <IndexMainTitle>
        <i18n-t v-if="locale === 'fr'" keypath="index.title.signupPros">
          <template #lineBreakHiddenSm><br class="sm:tw-hidden" /></template>
          <template #lineBreak><br /></template>
          <template #lineBreakHiddenLg><br class="lg:tw-hidden" /></template>
          <template #lineBreakHiddenBlockLg
            ><br class="tw-hidden lg:tw-block"
          /></template>
          <template #lineBreakHiddenBlockSmHiddenLg
            ><br class="tw-hidden sm:tw-block lg:tw-hidden"
          /></template>
        </i18n-t>
        <i18n-t v-else keypath="index.title.signupPros">
          <template #lineBreakHidden600><br class="600:tw-hidden" /></template>
          <template #lineBreak><br /></template>
        </i18n-t>
      </IndexMainTitle>
      <IndexSignupPros class="mainParentContainer" />
    </div>
    <div class="mainParentContainer ignoreFontUpscale">
      <SeoFooter :lists="seoListing" />
    </div>
    <PageFooter />
  </div>
</template>

<style scoped lang="scss">
.adjustToPagePadding {
  padding-left: var(--grid-pad-adjust);
  padding-right: var(--grid-pad-adjust);
}

.adjustBg,
.offsettedBackground::before {
  @apply tw-bg-orange-500 tw-bg-opacity-5;
}

.offsettedBackground {
  @apply tw-relative tw-w-full;

  &::before {
    --offset: 124px;
    content: '';
    position: absolute;
    top: var(--offset);
    left: 0px;
    z-index: -1;
    width: 100%;
    height: calc(100% - var(--offset));

    @screen lg {
      --offset: 64px;
    }
  }

  &.ourValuesContainer::before {
    --offset: 250px;
    @screen sm {
      --offset: 238px;
    }
    @screen 600 {
      --offset: 201px;
    }
    @screen md {
      --offset: 225px;
    }
  }
  &.ourValuesContainer.english::before {
    @screen 600 {
      --offset: 252px;
    }
    @screen md {
      --offset: 286px;
    }
    @screen lg {
      --offset: 225px;
    }
  }
  &.videoPresentationContainer::before {
    --offset: 336px;
    @screen 375 {
      --offset: 353px;
    }
    @screen md {
      --offset: 404px;
    }
    @screen lg {
      --offset: 61px;
    }
  }
  &.successStoriesContainer::before {
    --offset: 87px;
    @screen lg {
      --offset: 104px;
    }
  }
  &.blogContainer::before {
    --offset: 87px;
    @screen lg {
      --offset: 104px;
    }
  }
}

.bigOffset::before {
  --offset: 356px;
}

.bottomSpacing {
  @apply tw-mb-12;

  @screen md {
    @apply tw-mb-20;
  }
}

.upDownSpacer {
  @apply tw-my-12;

  @screen md {
    @apply tw-my-20;
  }
}

.titleBottomSpacing {
  @apply tw-mb-6;

  @screen md {
    @apply tw-mb-10;
  }
}

.btnWrapTopSpacing {
  @apply tw-mt-6;

  @screen md {
    @apply tw-mt-10;
  }
}
</style>
